import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  Navigate, useParams
} from 'react-router-dom';
import HomeLandingPage from './pages/Home/HomeLandingPage';
import HomeLandingPageFinsus from './finsus/pages/Home/HomeLandingPage';
import { themeValues } from './theme/theme';
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
  Box
} from '@mui/material';
import MemberShipSummary from './pages/MembershipSummary/MembershipSummary';
import Register from './pages/Register';
import MembershipCoveragePage from './pages/MembershipCoverage/MembershipCoveragePage';
import CrearCuenta from './pages/CrearCuenta';
import Navbar from './components/organisms/Navbar/Navbar';
import FooterInter from './components/Footer/Footer';
import ChooseCoverage from './components/organisms/ChooseCoverage';
import Referred from './pages/Referred/Referred';
import CustomerPortal from './pages/CustomerPortal';
import Removed from './pages/RemovedAccounts/index';

import HiringPaymentMembership from './pages/HiringPaymentMembership';
import MakePayment from './pages/HiringPaymentMembership/MakePayment';
import TrackingStatus from './pages/TrackingStatus';
import Recomendation from './pages/Recomendation';
import CoverageLimitExceeded from './pages/CoverageLimitExceeded';
import './App.css';
import ConfirmCellphoneData from './pages/ConfirmCellphoneData';
import ReparacionCelular from './pages/ReparacionCelular/ReparacionCelular';
import CellPhoneEditData from './components/templates/CellphoneEditData';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import { Auth0ProviderWithNavigate } from './auth/auth0Provider';
import { ContextAuth0 } from './auth/contextAPIProvider';
import Redirect from './pages/Redirect';
import TelemedicinaComponent from './components/templates/Telemedicina/TelemedicineCustomComponent';
import Benefiaciary from './pages/Beneficiary';
import AddNewBeneficiary from './pages/AccidentalDeath/Beneficiary';
import TermsAndConditions from './components/organisms/TermsAndConditions';

// import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Login from './pages/Login';
import ErrorPagina from './pages/ErrorPagina';
import { addAccessToken } from './api/config';
import IframePage from './pages/Telemedicina/IframePage';
import InterFAQs from './pages/InterFAQs';
import moment from 'moment-timezone';
import AccidentalDeathPage from './pages/AccidentalDeath';
import AditionalDocumentsPage from './pages/AditionalDocuments';
import CellPhoneTheftPage from './pages/cellPhoneTheft/index';
import PaymentOk from './components/templates/MakePayment/PaymentOk';
import OkDeleteAccount from './pages/RemovedAccounts/OkDeleteAccount';
import BASE_CONFIG from './env/env';
import ImeiValidationPage from './pages/ImeiPage/ImeiValidationPage';
import RedirectTrackingId from './pages/RedirectTrackingId';
import ErrorLogin from "./pages/ErrorLogin";
import ForgotPassword from "./pages/ForgotPassword";
import ServerCrash from "./pages/ServerCrash";
import NewPassword from "./pages/NewPassword";
import PasswordResetResponse from "./pages/PasswordResetResponse";
import NewProductRegister from './pages/NewProductRegister';
import MaintenancePage from "./pages/MaintenancePage";
import AllianceRegister from './pages/AllianceRegister';
import NumeroDeEmpleado from './components/organisms/Form/FormContent/NumeroDeEmpleado';
import LandingAlliance from "./pages/LandingAlliance/LandingAlliance";
import {
  apiError as ErrorAlliance,
  apiLoading as LoadingAlliance,
  apiSuccess as SuccessAlliance
} from "../src/api/allianceSettingSlice";
import useFetch from "../src/hooks/useFetch";
import AllianceFAQs from "../src/pages/AllianceFAQs";
import TrackingStatusCheckUpMedico from "./pages/TrackingStatusCheckUpMedico";
import BenefitDetailsPage from './pages/BeneftiDetailsPage';
// import { myColorPalette } from './theme/themeGenerated';
import SurveyForm from './pages/RemovedAccounts/SurveyForm';
import PendingPaymentComponent from './components/organisms/ReparationScreen/PendingPayment';
import NoteDetails from './components/organisms/ReparationScreen/NoteDetails';
import IdleEmail from './components/organisms/ReparationScreen/IdleEmail';
// import ElementContainer from './components/templates/MakePayment/PaymentElement';


const timezone: string = 'America / Mexico_city'
moment.tz.setDefault(timezone);
moment.locale('es');

let theme = createTheme(themeValues);
theme = responsiveFontSizes(theme);

const useStyles = makeStyles({
  content: {
    width: '100%',
  },
});

const ScrollToTop = () => {
  const location = useNavigate();

  React.useEffect(() => {
    const body = document.querySelector("body") as HTMLElement
    body.scrollIntoView({ behavior: 'smooth', block: 'start' })
    body.scrollTo(0, 0);
  }, [location]);

  return null;
};

// const stripePromise = loadStripe('pk_test_51Nro7LIGOnYkVbIoh4Yr1WseaM50bi4j9VCY6msB6LcHoUk9MxHdZGjO8PCrBMNXNBTRbeXVvJXN9F05Ahx12u4r00uR0avozu');
const stripePromise = loadStripe(BASE_CONFIG.stripe.publicKey);

declare let auth0: any;

declare global {
  interface Window {
    dataLayer: any;
  }
}

const PrivateRoutes = ({ children }: any) => {
  if (sessionStorage?.access_token) {
    return <React.Fragment>{children}</React.Fragment>
  }
  else {
    return <Navigate to={"/iniciar-sesion"} replace />;
  }
}

const AllianceRoutes = ({ children }: any) => {
  let path = window.location.pathname.includes("landing-alliance") || window.location.pathname.includes("finsus")
  let { id } = useParams();
  const { fetchData } = useFetch();

  const fetchingDataAlliance = (id: string) => {
    return fetchData(`catalogs/${id}`, 'GET', {}, {}
      , LoadingAlliance, SuccessAlliance, ErrorAlliance);
  }

  useEffect(() => {
    if ((id && path) || (window.location.pathname === "/finsus")) {
      fetchingDataAlliance(id || "finsus")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, path]);

  if ((path && id) || (window.location.pathname === "/finsus")) {
    return <React.Fragment>{children}</React.Fragment>
  }
  else {
    return <Navigate to={"/iniciar-sesion"} replace />;
  }
}

const App: React.FC = () => {
  const classes = useStyles();
  const [auth0Value, setAuth0Value]: any = useState("");
  const [checkIdle, setCheckIdle] = useState<boolean>(false);

  // const [isLogged, setIsLogged] = useState<boolean>(false);
  // const domain = 'https://dev-aoduc5a87v5yx5xz.us.auth0.com/';
  // const clientID = 'HhgQpANqJUN386wmCqlP7I41bhosIj5s';
  const domain = BASE_CONFIG.auth0.domain;
  const clientID = BASE_CONFIG.auth0.clientId;



  React.useEffect(() => {
    if (!auth0Value) {
      const webAuth = new auth0.WebAuth({
        domain,
        clientID,
        redirectURI: `${BASE_CONFIG.base}/portal-del-cliente`,
        responseType: "token",
        cacheLocation: 'localstorage',
        scope: 'openid profile email'
      });
      // webAuth.crossOriginVerification();
      // webAuth.popup.callback();
      setAuth0Value(webAuth);
    }
  }, [auth0Value]);

  React.useEffect(() => {
    async function SesionToken() {
      if (sessionStorage["access_token"]) {
        await addAccessToken();
      }
    }
    SesionToken();
  }, [])

  return (
    <BrowserRouter>
      <ContextAuth0.Provider value={auth0Value}>
        <Auth0ProviderWithNavigate>
          <ThemeProvider theme={theme}>
            {/* <ButtonFloating>
            </ButtonFloating> */}
              <main className={classes.content}>
                <ScrollToTop />
                <Box id="main-box" component="main" sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                  {/* <Idle check={checkIdle} /> */}
                  <Navbar />
                  <Routes>
                    <Route path='/' element={<HomeLandingPage />} />
                    <Route path='/finsus' element={<AllianceRoutes><HomeLandingPageFinsus /></AllianceRoutes>} />
                    <Route path='/landing-alliance?/:id?' element={<AllianceRoutes><LandingAlliance /></AllianceRoutes>} />
                    <Route path='/resumen-membresia' element={<MemberShipSummary />} />
                    {/* <Route path='/registrate' element={<Register />} /> */}
                    <Route path='/alianza/empleado' element={<NumeroDeEmpleado />} />
                    <Route path='/registrate/:idStepper' element={<Register />} />
                    <Route path='/finsus/registrate/:idStepper' element={<Register />} />
                    <Route path='/alianza/registrate/:idStepper' element={<AllianceRegister />} />
                    <Route path='/tracking/:id' element={<RedirectTrackingId />} />
                    <Route path='/elegir-cobertura' element={<ChooseCoverage />} />
                    <Route path='/cobertura' element={<MembershipCoveragePage />} />
                    <Route path='/crear-cuenta' element={<CrearCuenta />} />
                    <Route path='/error-login' element={<ErrorLogin />} />
                    <Route path='/olvidaste-contrasena' element={<ForgotPassword />} />
                    <Route path='/error-de-servidor' element={<ServerCrash />} />
                    <Route path='/reset-password/:id' element={<NewPassword />} />
                    <Route path='/password-reset-response' element={<PasswordResetResponse />} />
                    <Route path='/iniciar-sesion' element={<Login />} />
                    <Route path='/Error?' element={<ErrorPagina />} />
                    <Route path='/mantenimiento' element={<MaintenancePage />} />
                    <Route path='/undefined' element={<ErrorPagina />} />
                    <Route path='/referidos' element={<Referred />} />
                    <Route path='/portal-del-cliente' element={<PrivateRoutes><CustomerPortal /></PrivateRoutes>} />

                    <Route path='/datos-del-cliente' element={<PrivateRoutes><Removed /></PrivateRoutes>} />
                    <Route path='/contratacion' element={<MakePayment />} />
                    <Route
                      path='/contratacion/realizar-pago'
                      element={<HiringPaymentMembership />}
                    />
                    <Route path='/telemedicina' element={<IframePage />} />
                    <Route path='/ofertas' element={<PrivateRoutes><IframePage /></PrivateRoutes>} />
                    <Route path='/telemedicinaCustom' element={<TelemedicinaComponent />} />
                    <Route path='/estatus' element={<TrackingStatus />} />
                    <Route path='/estatus-check-up-medico' element={<TrackingStatusCheckUpMedico />} />
                    <Route path='/recomendacion' element={<Recomendation />} />
                    <Route path='/limite-excedido' element={<CoverageLimitExceeded />} />
                    <Route path='/confirmar-datos/:idStepper' element={<ConfirmCellphoneData />} />
                    {/* <Route path='/reparacion-costo' element={<RepairCost />} /> */}
                    {/* <Route path='/email-pago' element={<PaymentEmail />} /> */}
                    <Route path='/redirect' element={<Redirect />} />
                    <Route path='/editar-informacion' element={<CellPhoneEditData />} />
                    {/* <Route path='/beneficiario' element={<Benefiaciary />} /> */}
                    <Route path='/preguntas-frecuentes' element={<InterFAQs />} />
                    <Route path='/finsus/preguntas-frecuentes' element={<InterFAQs />} />
                    <Route path='/preguntas-frecuentes-alianza' element={<AllianceFAQs />} />
                    <Route path='/beneficiario' element={<PrivateRoutes><Benefiaciary /></PrivateRoutes>} />
                    <Route path='/muerte-accidental' element={<AccidentalDeathPage />} />
                    <Route path='/muerte-accidental/beneficiario' element={<AddNewBeneficiary />} />
                    <Route path='/documentos-adicionales' element={<AditionalDocumentsPage />} />
                    <Route path='/terminos-y-condiciones' element={<TermsAndConditions />} />
                    <Route path='/survey-form' element={<SurveyForm />} />

                    <Route path='/finsus/terminos-y-condiciones' element={<TermsAndConditions />} />
                    <Route path='/robo-celular' element={<CellPhoneTheftPage />} />
                    <Route path='/payment' element={<PaymentOk />} />

                    <Route path='/goodbye' element={<PrivateRoutes><OkDeleteAccount /></PrivateRoutes>} />

                    <Route path='/registrar-imei' element={<ImeiValidationPage />} />
                    <Route path='/portal-del-cliente/registro-nuevo-producto' element={<PrivateRoutes><NewProductRegister /></PrivateRoutes>} />
                    <Route path='/beneficio/:benefit' element={<BenefitDetailsPage />} />
                    <Route path='/reparacion-celular' element={<ReparacionCelular />} />
                    <Route path='/pago-pendiente' element={<PendingPaymentComponent />} />
                    <Route path='/aviso' element={<NoteDetails />} />
                    <Route path='/email-espera' element={<IdleEmail />} />
                    <Route path='*' element={<PageNotFound />} />
                  </Routes>
                </Box>
              </main>
              <FooterInter />
          </ThemeProvider>
        </Auth0ProviderWithNavigate>
      </ContextAuth0.Provider>
    </BrowserRouter>
  );
};

export default App;
