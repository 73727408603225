import * as React from 'react';
import AccordionDetails from '@mui/material/AccordionDetails';
import QuestionsPlus from '../../../assets/Questions/QuestionsPlus';
// import Link from '@mui/material/Link';
import { Link } from 'react-router-dom';
import './question.css';
import { Accordion, AccordionSummary, Typography } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import { useMediaQuery, useTheme } from '@mui/material';


export default function BasicAccordion() {

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [expandedSecond, setExpanded2] = React.useState<string | false>(false);
  const [expandedThird, setExpanded3] = React.useState<string | false>(false);
  const [expandedFourth, setExpanded4] = React.useState<string | false>(false);
  const [expandedFived, setExpanded5] = React.useState<string | false>(false);
  const [expandedSix, setExpanded6] = React.useState<string | false>(false);
  const { breakpoints } = useTheme();

  const isDesktop = useMediaQuery(breakpoints.up('lg'));


  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (panel === "panel1") {
        setExpanded(isExpanded ? panel : false);
        if (isExpanded) {
          window.dataLayer.push({
            event: 'TRUS_faq',
            faq: '¿Qué es #TeRegalamosUnSeguro?',
          })
        }
      } else if (panel === "panel2") {
        setExpanded2(isExpanded ? panel : false);
        if (isExpanded) {
          window.dataLayer.push({
            event: 'TRUS_faq',
            faq: '¿Por qué es de regalo?',
          })
        }
      }
      else if (panel === "panel3") {
        setExpanded3(isExpanded ? panel : false);
        if (isExpanded) {
          window.dataLayer.push({
            event: 'TRUS_faq',
            faq: '¿Cómo puedo acceder a la protección de #TeRegalamosUnSeguro?',
          })
        }
      }
      else if (panel === "panel4") {
        setExpanded4(isExpanded ? panel : false);
        if (isExpanded) {
          window.dataLayer.push({
            event: 'TRUS_faq',
            faq: '¿Qué coberturas incluye el producto que va por cuenta de inter.mx?',
          })
        }
      }
      else if (panel === "panel5") {
        setExpanded5(isExpanded ? panel : false);
        if (isExpanded) {
          window.dataLayer.push({
            event: 'TRUS_faq',
            faq: '¿Qué coberturas incluye el producto Premium?',
          })
        }
      }
      else if (panel === "panel6") {
        setExpanded6(isExpanded ? panel : false);
        if (isExpanded) {
          window.dataLayer.push({
            event: 'TRUS_faq',
            faq: '¿Cuáles son los requisitos para obtener #TeRegalamosUnSeguro?',
          })
        }
      }
    };

  const AcordionCustom: React.CSSProperties = {
    boxShadow: 'unset',
    borderRadius: '0px',
    textAlign: 'left',
  }
  const AcordionContainer: React.CSSProperties = {
    borderBottom: '2px solid gray'
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <div
        style={{
          maxWidth: '1140px',
          //maxHeight: '403px'
        }}

      >
        <Typography
          color={'var(--black, #212121)'}
          textAlign={'center'}
          fontSize={'20px'}
          fontStyle={'normal'}
          fontWeight={700}
        >
          Preguntas frecuentes
        </Typography>
        <div style={AcordionContainer}>
          <Accordion style={AcordionCustom} expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
            <AccordionSummary expandIcon={expanded === 'panel1' ? < RemoveIcon /> : < QuestionsPlus />}
            >
              <Typography
                color={'var(--black-75, #595959)'}
                fontSize={'16px'}
                fontWeight={700}

              >¿Qué es #TeRegalamosUnSeguro?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                color={'var(--black-75, #212121)'}
                fontSize={'16px'}
                fontWeight={400}
              >
                #TeRegalamosUnSeguro es una iniciativa que tiene como objetivo principal
                proteger a todos los mexicanos SIN COSTO para ellos, además de acercarlos al
                mundo de los seguros, dándoles la opción de elegir cómo quieren estar protegidos.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
        <div style={AcordionContainer}>
          <Accordion style={AcordionCustom} expanded={expandedSecond === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary
              expandIcon={expandedSecond === 'panel2' ? < RemoveIcon /> : < QuestionsPlus />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography
                color={'var(--black-75, #595959)'}
                fontSize={'16px'}
                fontWeight={700}

              >¿Por qué es de regalo?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                color={'var(--black-75, #212121)'}
                fontSize={'16px'}
                fontWeight={400}
              >
                Esta vez, en lugar de gastar en más marketing y anuncios, decidimos usar ese
                dinero para compartir contigo un poco de esa inversión y que puedas vivir la
                experiencia de estar asegurado.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
        <div style={AcordionContainer}>
          <Accordion style={AcordionCustom} expanded={expandedThird === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary
              expandIcon={expandedThird === 'panel3' ? < RemoveIcon /> : < QuestionsPlus />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography
                color={'var(--black-75, #595959)'}
                fontSize={'16px'}
                fontWeight={700}


              >¿Cómo puedo acceder a la protección de #TeRegalamosUnSeguro?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                color={'var(--black-75, #212121)'}
                fontSize={'16px'}
                fontWeight={400}
              >
                ¡Es muy sencillo! Sólo debes registrarte en la página y activar tu cuenta,
                así tendras acceso a tu portal donde podrás ver todos tus beneficios.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
        <div style={AcordionContainer}>
          <Accordion style={AcordionCustom} expanded={expandedFourth === 'panel4'} onChange={handleChange('panel4')}>
            <AccordionSummary
              expandIcon={expandedFourth === 'panel4' ? < RemoveIcon /> : < QuestionsPlus />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography
                color={'var(--black-75, #595959)'}
                fontSize={'16px'}
                fontWeight={700}
              >¿Qué coberturas incluye el producto que va por cuenta de inter.mx?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                color={'var(--black-75, #212121)'}
                fontSize={'16px'}
                fontWeight={400}
              >
                Rotura de pantalla hasta por $4,000, muerte accidental por $20,000,
                además de telemedicina ilimitada y club de descuentos en miles de comercios.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
        <div style={AcordionContainer}>
          <Accordion style={AcordionCustom} expanded={expandedFived === 'panel5'} onChange={handleChange('panel5')}>
            <AccordionSummary
              expandIcon={expandedFived === 'panel5' ? < RemoveIcon /> : < QuestionsPlus />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography
                color={'var(--black-75, #595959)'}
                fontSize={'16px'}
                fontWeight={700}
              >¿Qué coberturas incluye el producto Premium?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                color={'var(--black-75, #212121)'}
                fontSize={'16px'}
                fontWeight={400}
              >
                Rotura de pantalla hasta por $4,000, seguro de robo con violencia hasta por $10,000,
                muerte accidental por $20,000, telemedicina ilimitada y club de descuentos en miles de comercios.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
        <div style={AcordionContainer}>
          <Accordion style={AcordionCustom} expanded={expandedSix === 'panel6'} onChange={handleChange('panel6')}>
            <AccordionSummary
              expandIcon={expandedSix === 'panel6' ? < RemoveIcon /> : < QuestionsPlus />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography
                color={'var(--black-75, #595959)'}
                fontSize={'16px'}
                fontWeight={700}
              >¿Cuáles son los requisitos para obtener #TeRegalamosUnSeguro?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                color={'var(--black-75, #212121)'}
                fontSize={'16px'}
                fontWeight={400}
              >
                Para poder registrarte sólo debes ser mayor de edad y tener nacionalidad Mexicana.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: isDesktop ? 'row' : 'column',
            justifyContent: 'center',
            alignItems: isDesktop ? 'flex-end' : '',
            marginBottom: "40px"
          }}
        >
          <Typography
            //color={'var(--black, #212121)'}
            fontSize={'16px'}
            fontWeight={400}
            style={{
              cursor: 'pointer',
              marginTop: '40px',
              marginRight: '5px'
            }}
            textAlign={"center"}

          >
            Para más info consulta nuestra sección de {'                   '}
          </Typography>

          {
            window.location.pathname !== "/finsus" &&
            <Link to={'/preguntas-frecuentes'} color='#009DD0'>
              <Typography
                color={'#009DD0'}
                fontSize={'16px'}
                fontWeight={400}
                style={{
                  cursor: 'pointer',
                  color: '#009DD0',
                  textDecoration: '#009DD0'
                }}
                textAlign={"center"}
              >
                {' '}preguntas frecuentes
              </Typography>
            </Link>
          }
          {
            window.location.pathname === "/finsus" &&

            <Link to={'/finsus/preguntas-frecuentes'} color='#009DD0'>
              <Typography
                color={'#009DD0'}
                fontSize={'16px'}
                fontWeight={500}
                style={{
                  cursor: 'pointer',
                  color: '#009DD0',
                  textDecoration: '#009DD0'
                }}
                textAlign={"center"}
              >
                {' '}preguntas frecuentes
              </Typography>
            </Link>
          }



        </div>


      </div>
    </div>
  );
}