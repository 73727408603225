import styled from 'styled-components';
import React from 'react';
import { Button, ButtonProps } from '@mui/material';
interface ButtonInterProps extends ButtonProps {
}
const ButtonInter: React.FC<ButtonInterProps> = (props) => {
    const ButtonInterStyle: React.CSSProperties = {
        borderRadius: 8,
        width: "100%",
        padding: "14px 16px",
        fontSize: 16,
        fontWeight: 600,
        lineHeight: 'normal',
        ...props.style,
    }
    return (<>
        <Button {...props} size='medium' style={ButtonInterStyle}>
            {props.children}
        </Button>
    </>)
}

export default ButtonInter