import React, { useState, useEffect } from 'react';
import {
    useField,
    useFormikContext
} from 'formik';
import FormItemGenerate from '../../../atoms/FormItems/FormItemGenerate';
import useMediaQuery from '@mui/material/useMediaQuery';

type DatosPersonalesProps = {
    errors: any;
    handleChange?: any;
    handleBlur?: any;
    multipleBeneficiaries?: boolean;
    countBeneficiaries?: number;
    porcentageLimit?: number;
}

const DatosDeBeneficiario: React.FC<DatosPersonalesProps> = ({ errors, handleChange, handleBlur, multipleBeneficiaries, countBeneficiaries = 0, porcentageLimit }) => {
    const formik = useFormikContext();
    const matches = useMediaQuery('(min-width:600px)');
    const [name, nameMeta] = useField("name");
    const [middle_name, middle_nameMeta] = useField("middle_name");
    const [last_name, last_nameMeta] = useField("last_name");
    const [birthdate, birthdateMeta] = useField("birthdate");
    const [email, emailMeta] = useField("email");
    const [phone, phoneMeta] = useField("phone");
    const [porcentage, porcentageMeta] = useField("porcentage");
    const [parentesco, setParentesco] = useState('');
    const [parentescoOption, setParentescoOption] = useState(['Padre', 'Madre', 'Abuelo', 'Abuela', 'Hermano', 'Hermana', 'Hija', 'Hijo', 'Esposo' ,'Esposa', 'Pareja']);
    const [parentescoField, parentescoMeta] = useField("relationship");

    const items = [{
        id: 1, // identificador para la key
        label: 'Nombre(s)', // texto en el input
        name: 'name', // identificador que sera usado por formik
        as: 'textField', // tipo de elemento en este caso un texfield de material UI
        error: name, // asigna la referencia de error al input en formik desde yup
        meta: nameMeta, // contiene el error y evento del elemento
        autofocus: true
    },
    {
        id: 2,
        label: 'Primer Apellido',
        name: 'middle_name',
        as: 'textField',
        error: middle_name,
        meta: middle_nameMeta,
        xs: matches ? 6 : 12
    },
    {
        id: 3,
        label: 'Segundo Apellido',
        name: 'last_name',
        as: 'textField',
        error: last_name,
        meta: last_nameMeta,
        xs: matches ? 6 : 12
    },
    {
        id: 5,
        label: 'Fecha de Nacimiento*',
        name: 'birthdate',
        as: 'textField',
        type: 'date',
        error: birthdate,
        meta: birthdateMeta,
        limit: {
            minAge: 18,
            maxAge: 65
        }
    },
    {
        id: 6,
        label: 'Parentesco*',
        name: 'relationship',
        type: 'select',
        event: setParentesco,
        value: parentesco,
        option: parentescoOption,
        error: parentescoField,
        meta: parentescoMeta
    },
    {
        id: 7,
        label: 'Correo electrónico beneficiario',
        name: 'email',
        as: 'textField',
        error: email,
        meta: emailMeta
    },
    {
        id: 8,
        label: 'Número de celular beneficiario',
        name: 'phone',
        as: 'textField',
        error: phone,
        meta: phoneMeta
    },
    {
        id: 9,
        label: 'Porcentaje asignado*',
        name: 'porcentage',
        as: 'slider',
        type: 'slider',
        error: porcentage,
        meta: porcentageMeta,
        limit: {
            min: 0,
            max: 100
        },
        value: porcentage.value,
        disabled: multipleBeneficiaries && !countBeneficiaries
    }
    ]

    useEffect(() => {
        if(countBeneficiaries <= 0) {
            formik.setFieldValue('porcentage', 100)
        }
    }, [])

    return (<React.Fragment>
        <FormItemGenerate
            handleChange={handleChange}
            handleBlur={handleBlur}
            items={items}
        />
    </React.Fragment>)
};

export default DatosDeBeneficiario;