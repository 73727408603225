enum BenefitGeneral {
    Muerte = 'Muerte accidental',
}

enum BeneifitExtra {
    DescuentosInter = 'Descuentos Inter.mx',
    Telemedicina = 'Telemedicina general ilimitada',
    Descuento = 'Club de descuentos',
}

enum BenefitCelular {
    Reparacion = 'Reparación de pantalla',
    Robo = 'Robo de celular',
}

enum BenefitSalud {
    PagoMedicamentos = 'Pago de medicamentos',
    ReembolsoGastosMedicos = 'Reembolso de gastos médicos',
    CheckUpMedico = 'Estudio de laboratorio (Check up)',
    PerdidasOrganicas = 'Pérdidas Orgánicas',
    InvalidezTotal = 'Invalidez total y permanente por accidente',
    ApoyoIncapacidad = 'Apoyo de incapacidad por accidente',
}

export const BenefitList = {
    ...BenefitGeneral,
    ...BeneifitExtra,
    ...BenefitCelular,
    ...BenefitSalud,
};

export type BenefitListType = keyof typeof BenefitList;

//OLD WAY
export enum BenefitValues {
    Reparacion = "Reparación de pantalla",
    Descuento = "Club de descuentos",
    Telemedicina = "Telemedicina general ilimitada",
    Muerte = "Muerte accidental",
    Robo = "Robo de celular",
    DescuentosInter = "Descuentos Inter.mx",
    PagoMedicamentos = "Pago de medicamentos",
    ReembolsoGastosMedicos = "Reembolso de gastos médicos",
    CheckUpMedico = "Estudio de laboratorio (Check up)",
    PerdidasOrganicas = "Pérdidas Orgánicas",
    InvalidezTotal = 'Invalidez total y permanente por accidente',
    ApoyoIncapacidad = "Apoyo de incapacidad por accidente"
}
export type BenefitValuesType = keyof typeof BenefitValues;

export enum StatusReparacion {
    none = "none",
    Inprogress = "Inprogress",
    Issued = "Issued",
    Complete = "Complete",
    Cancel = "Cancel"
}

export enum StatusUassits {
    none = "none",
    pagoCopago = "pago copago",
    esperandoEquipo = "esperando equipo",
    enCamino = "en camino",
    pagoExtra = "pago extra",
    entregado = "entregado",
    cancelado = "cancelado",
    enDiagnostico = "en diagnostico",
    enReparacion = "en reparación",
    enviado = "enviado",

}   