import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { BenefitsDetails } from '../../CustomerPortalContent';
import chevronRight from '../../assets/chevronRight.png'
import { Chip, Stack } from '@mui/material';
import { benefitGetKeyByName } from './benefit.tools';
import moment from 'moment';
import { BadgeList, IBenefitBadge, ReparacionCelular } from './benefit.badge.values';
import { MembershipCategoryType } from '../../../Membership/MembershipMainValues';
import { BenefitValues, BenefitValuesType, StatusReparacion, StatusUassits } from './benefit.values';
import { BenefitConfiguration, BenefitData } from './benefit.config';
import { useNavigate } from 'react-router-dom';
import BenefitModalComponent from './benefit.modal';
import { CapitalizeText, getStatusRepairPhone, setStatusRepairPhone } from '../../../../../utils/utils';
import { MembershipPlanItem } from '../../interfaces/membership';
import ModalCheckUp from '../../../../../pages/CustomerPortal/ModalCheckUp';
import { useSelector } from 'react-redux';
import { IUserData } from '../../interfaces/user';

const BenefitItem = styled.div`
    display:flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 15px;
    font-weight: 600;

    &:not(.disabled):hover{
        background: whitesmoke;
        border-radius: 0px 0px 10px 10px;
    }
`;

const BenefitBadge = styled.div`
    color:white;
    font-weight: 200;
    font-size:11px;
    padding:5px;
    border-radius: 12px 12px 12px 12px;
`

export interface ModalValues {
    content: (data?: any) => React.ReactNode;
    imageAvatar: string,
    buttonText: string,
    description?: string,
    path?: string;
}
interface BenefitItemProps {
    benefit: BenefitsDetails,
    benefitKey: BenefitValuesType,
    defautWaitDays: number,
    index: number;
    user: {
        imei: null | boolean | undefined;
        beneficiary: boolean;
        fullname: string;
    };
    created_at: string;
    badge?: IBenefitBadge;
    disabled?: boolean;
    membership: MembershipPlanItem;
}

// type DataJSONToShow = 'check_up_timer'| 'all_events_used' | 'event_used' | 'event_lost_with_more_events' | 'event_lost_without_events_pendings';

const BenefitItemComponent: React.FC<BenefitItemProps> = ({
    benefit,
    index,
    benefitKey,
    defautWaitDays,
    badge,
    user,
    created_at,
    disabled,
    membership,
}) => {
    const benefitConfiguration = BenefitConfiguration[benefitKey];
    const waitDays = benefitConfiguration && benefitConfiguration.waitDays !== undefined ? benefitConfiguration.waitDays : defautWaitDays;
    const validateDate = (date: string): boolean => {
        // Parse la fecha de entrada
        const parsedDate = moment(date);
        // Verifica si la fecha es válida
        if (!parsedDate.isValid()) {
            console.error('Fecha no válida');
            return false;
        }
        // Obtiene la fecha actual
        const currentDate = moment();
        // Calcula la diferencia en días que han pasado
        const daysDifference = currentDate.diff(parsedDate, 'days');
        // Verifica si han pasado al menos 30 días o los dias que se requieran
        return daysDifference >= waitDays;
    };

    const getRemainingDays = (date: string) => {
        // Parse la fecha de entrada
        const parsedDate = moment(date);
        // Verifica si la fecha es válida
        if (!parsedDate.isValid()) {
            console.error('Fecha no válida');
            return NaN;
        }
        // Obtiene la fecha actual
        const currentDate = moment();
        // Calcula la diferencia en días que han pasado
        const daysDifference = currentDate.diff(parsedDate, 'days');
        // Resta a los dias de espera, la cantidad de dias que han pasado
        return waitDays - daysDifference
    }
    const navigate = useNavigate()
    const validDays: boolean = validateDate(created_at);
    const [remainingDays, setRemainingDays] = useState<number>(getRemainingDays(created_at));
    const [path, setPath] = useState<string>('')
    const [badgeValue, setBadgeValue] = useState<IBenefitBadge>(badge ? badge : { color: '', message: '' })
    //Validacion final del componente
    const [valid, setValid] = useState<boolean>(false);
    const [secondaryValid, setSecondaryValid] = useState<boolean>(false);
    const [disabledItem, setDisabledItem] = useState<boolean>(disabled !== undefined ? disabled : true);
    //Configuracion de modales
    const [open, setOpen] = useState<boolean>(false);
    const [openCheckUpModal, setOpenCheckUpModal] = useState<boolean>(false);
    const [badgeEvent, setBadgeEvent] = useState<any>(null);
    // obtener datos de redux/persist
    const assignment = useSelector((state: any) => state.assignment.response);
    const assignmentModal = useSelector((state: any) => state.assignmentModal.response);
    const userResponse: IUserData = useSelector((state: any) => state.user.response.data);
    const currentValue = useSelector((state: any) => state.productsCards.currentValue);
    const statusReparacion = useSelector((state: any) => state.status.response);
    const ModalDiasEsperaContent = () => {
        return <p>¡Hola <strong>{CapitalizeText(user ? user.fullname : '')}</strong>! Para utilizar esta cobertura, hay un período de espera de {remainingDays} {remainingDays > 1 ? 'días' : 'dia'}. Esto significa que si ocurriera algún incidente antes de que entre en vigencia, lamentablemente no podríamos cubrirlo.</p>
    }
    const ModalPendienteImei = () => {
        return <p> Hola <strong>{CapitalizeText(user ? user.fullname : '')}</strong>, para poder usar tu cobertura de reparación de pantalla es muy importante que nos dejes el <strong>IMEI</strong> del celular que registraste.</p>
    }

    const listBadgeTags = {
        check_up_timer: {
            color: '#E39217',
            message: '7 días para realizarte el estudio'
        },
        assigned_event: {
            color: '#E39217',
            message: 'Laboratorio asignado'
        },
        all_events_used: {
            color: '#5AB52F',
            message: 'Evento utilizado'
        },
        event_used: {
            color: '#5AB52F',
            message: 'Evento utilizado'
        },
        event_used_free: {
            color: '#5AB52F',
            message: 'Evento utilizado'
        },
        event_lost_with_more_events: {
            color: '#CD0505',
            message: 'Evento perdido'
        },
        event_lost_without_events_pendings: {
            color: '#CD0505',
            message: 'Evento perdido'
        },
        event_lost_free: {
            color: '#CD0505',
            message: 'Evento perdido'
        },
        event_pending: {
            color: '#90268D',
            message: 'En proceso'
        },
        not_event_found: {
            color: '#FFFFFF',
            message: ''
        }
    }

    const [currentModal, setCurrentModal] = useState<ModalValues>();
    const ModalDataTemplates = {
        diasDeEspera: {
            buttonText: 'Entendido',
            content: ModalDiasEsperaContent,
            imageAvatar: require('../../assets/avatar.png'),
            path: undefined,
            description: 'Bloqueo de Dias',
        } as ModalValues,
        imeiPendiente: {
            buttonText: 'Agregar',
            content: ModalPendienteImei,
            imageAvatar: require('../../assets/avatar.png'),
            path: '/registrar-imei',
            description: 'Bloqueo de Imei'
        } as ModalValues,


    }

    useEffect(() => {
        const eventLeft: any = userResponse?.events?.checkup;
        // const eventLeft: any = 0;
        if (localStorage.completado_sin_eventos && !openCheckUpModal && eventLeft === 0) {
            const status: any = assignment?.data?.status || assignmentModal?.data?.status;
            const disableBadge = (status === 'Completed' || status === 'Canceled') ? true : false;

            setBadgeEvent('not_event_found')
            setDisabledItem(disableBadge);
            setValid(false);
        }
        if ((localStorage.completado_con_eventos && eventLeft > 0) || (localStorage.evento_cancelado && eventLeft > 0)) {
            const path = '/beneficio/CheckUpMedico';

            setBadgeValue(listBadgeTags['not_event_found'])
            setBadgeEvent('not_event_found')
            setPath(path);
            setValid(true);
        }
        if (localStorage.evento_cancelado && eventLeft > 0) {
            setBadgeValue(listBadgeTags['not_event_found'])
            setBadgeEvent('not_event_found')
            setDisabledItem(false);
        }
        if (localStorage.evento_cancelado && eventLeft === 0) {
            setBadgeValue(listBadgeTags['not_event_found'])
            setBadgeEvent('not_event_found')
            setValid(false);
            setDisabledItem(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openCheckUpModal])

    useEffect(() => {
        if (benefitConfiguration) {
            setPath(benefitConfiguration.path);
        }
        if (!validDays) {
            setCurrentModal(ModalDataTemplates.diasDeEspera);
        } else {
            setDisabledItem(false);
        }
        
        switch (BenefitValues[benefitKey]) {
            case BenefitValues.PagoMedicamentos:
                setPath(`/beneficio/${BenefitValues.PagoMedicamentos}`)
                setValid(true);
                break;
            case BenefitValues.ReembolsoGastosMedicos:
                setPath(`/beneficio/${BenefitValues.ReembolsoGastosMedicos}`)
                setValid(true);
                break;
            case BenefitValues.PerdidasOrganicas:
                setPath(`/beneficio/${BenefitValues.PerdidasOrganicas}`)
                setValid(true);
                break;
            case BenefitValues.InvalidezTotal:
                setPath(`/beneficio/${BenefitValues.InvalidezTotal}`)
                setValid(true);
                break;
            case BenefitValues.ApoyoIncapacidad:
                setPath(`/beneficio/${BenefitValues.ApoyoIncapacidad}`)
                setValid(true);
                break;
            case BenefitValues.Muerte:
                if (!user.beneficiary) {
                    setDisabledItem(false);
                    setBadgeValue(BadgeList.pendingBeneficiary);
                    setSecondaryValid(user.beneficiary);
                    const path = `${benefitConfiguration?.path}${user.beneficiary ? '' : '/beneficiario'}`;
                    setPath(path)
                }
                setValid(user.beneficiary);
                break;
            case BenefitValues.CheckUpMedico:
                if (!validDays) {
                    setDisabledItem(true);
                } else {
                    const eventLeft: any = userResponse?.events?.checkup;
                    // const eventLeft: any = 0;
                    const status: any = assignment?.data?.status || assignmentModal?.data?.status;

                    const tableEvent: any = {
                        "Inprogress": 'event_pending',
                        "Completed": currentValue?.type === 'free' ? 'event_used_free' : eventLeft > 0 ? 'event_used' : 'all_events_used',
                        "Issued": 'assigned_event',
                        "Cancel": currentValue?.type === 'free' ? 'event_lost_free' : eventLeft > 0 ? 'event_lost_with_more_events' : 'event_lost_without_events_pendings'
                    };

                    const event: string = tableEvent[status] || 'not_event_found';
                    const listMessageBadge: any = listBadgeTags;

                    setBadgeEvent(event)
                    setBadgeValue(listMessageBadge[event]);
                    if (status === 'Inprogress' || status === 'Issued') {
                        const path = '/estatus-check-up-medico';
                        setPath(path);
                        setValid(true);
                    } else if (status) {
                        if (eventLeft === 0) {
                            setBadgeEvent(event)
                            setValid(false);
                            setDisabledItem(true);
                        }
                        if (localStorage.completado_sin_eventos && eventLeft === 0) {
                            const disableBadge = status === 'Completed' ? true : false;
                            setDisabledItem(disableBadge);
                            setBadgeEvent('not_event_found')
                        }
                        if (localStorage.completado_con_eventos && eventLeft > 0) {
                            setBadgeValue(tableEvent['not_event_found'])
                        }
                        if (localStorage.evento_cancelado && eventLeft > 0) {
                            setBadgeValue(tableEvent['not_event_found'])
                            setBadgeEvent('not_event_found')
                            setDisabledItem(false);
                        }
                        if (localStorage.evento_cancelado && eventLeft === 0) {
                            setBadgeValue(tableEvent['not_event_found'])
                            setValid(false);
                            setDisabledItem(true);
                        }
                    } else if (!tableEvent[status] && eventLeft > 0) {
                        const path = '/beneficio/CheckUpMedico';

                        setPath(path);
                        setValid(true);
                    } else {
                        setDisabledItem(true);
                        setValid(false);
                    }
                }
                break;
            case BenefitValues.Reparacion:
                const eventLeft: number = userResponse.events.gadgets;
                if (!validDays) {
                    setDisabledItem(true);
                }
                if (eventLeft === 0) {
                    setDisabledItem(true);
                }
                if (!user.imei) {
                    setDisabledItem(false);
                    setBadgeValue(BadgeList.pedingImegiRegister);
                    setCurrentModal(ModalDataTemplates.imeiPendiente);
                    setSecondaryValid(user.imei !== null && user.imei !== undefined ? true : false);
                }
                if (user.imei && validDays) {
                    if (statusReparacion?.data) {
                        const { status, estatus, guiaIda, guiaVuelta, pay_line, ligaCopago } = statusReparacion.data;
                        const homoEstatus = estatus?.toLocaleLowerCase() || 'none';
                        //const estatus: any = StatusUassits.pagoCopago;
                        switch (homoEstatus) {
                            case 'none':
                                setBadgeValue(ReparacionCelular.paymentPending);
                                setPath('/email-espera');
                                setDisabledItem(false)
                                break;
                            case StatusUassits.pagoExtra:
                                setBadgeValue(ReparacionCelular.paymentPending);
                                setPath('/pago-pendiente');
                                setDisabledItem(false)
                                break;
                            case StatusUassits.pagoCopago:
                                setBadgeValue(ReparacionCelular.paymentPending);
                                setPath('/email-espera');
                                setDisabledItem(false)
                                break;
                            case StatusUassits.enCamino:
                            case StatusUassits.enviado:
                            case StatusUassits.enDiagnostico:
                            case StatusUassits.esperandoEquipo:
                            case StatusUassits.enReparacion:
                                setBadgeValue(ReparacionCelular.onRepair);
                                setPath('/estatus');
                                setDisabledItem(false)
                                break;
                            default:
                                setPath('/reparacion-celular');
                        }
                        //Pago Confirmado
                        //En progreso
                        //Confirmar datos
                        //Estatus
                        //Reparacion celular

                    }
                    /*
                    if (getStatusRepairPhone() && getStatusRepairPhone().status === 1) {
                        setStatusRepairPhone({ status: 2, title: "Pago confirmado" })
                        setPath('/email-pago')
                    } else if (getStatusRepairPhone() && getStatusRepairPhone().status === 2) {
                        setPath('/confirmar-datos')
                    } else if (getStatusRepairPhone() && getStatusRepairPhone().status === 3) {
                        setPath('/estatus')
                    } else {
                        setPath('/reparacion-celular')
                    }*/
                }
                setValid(validDays && user?.imei ? true : false)
                break;
            default:
                setValid(validDays);
                setDisabledItem(!validDays)
                break;
        }
    }, [])

    return (
        <>
            <code style={{ display: 'none' }}>
                valid:{valid.toString()}
                <br />
                validDays:{validDays.toString()}
                <br />
                remainingDays:{remainingDays}
                <br />
                created_at:{created_at}
                <br />
                waitDays:{waitDays}
            </code>
            <BenefitItem className={disabledItem ? 'disabled' : ''} key={benefit.title} onClick={() => {
                if (validDays && valid && !disabledItem) {
                    navigate(path);
                } else {
                    if (BenefitValues[benefitKey] === BenefitValues.CheckUpMedico) {
                        setOpenCheckUpModal(true);
                    }
                    if (BenefitValues[benefitKey] === BenefitValues.Muerte && !user.beneficiary) {
                        const state = user.beneficiary ? {} : { porcentage: 0, beneficiaries: 0 }
                        navigate(path, { state });
                    } else {
                        setOpen(true);
                    }
                }
            }}>
                <div className='benefit.name' style={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'start',
                    flexDirection: 'column'
                }}>
                    {
                        badgeValue && badgeValue.message != '' && badgeValue.color != '' &&
                        <BenefitBadge className='benefit.badge' style={{ background: badgeValue.color, opacity: '1' }}>
                            {badgeValue.message}
                        </BenefitBadge>
                    }
                    <div className='benefit.title' style={{
                        cursor: !disabledItem ? 'pointer' : 'default',
                        opacity: !disabledItem ? '1' : '0.2'
                    }}>
                        <p style={{ padding: badge ? '5px' : '10px', margin: '0px' }}>
                            {benefit.title}
                        </p>
                    </div>
                </div>
                <div className='benefit.icon.arrow' style={{
                    cursor: !disabledItem ? 'pointer' : 'default',
                    opacity: !disabledItem ? '1' : '0.2',
                    display: 'flex', justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <img src={chevronRight} alt="" width={20} height={20} />
                </div>
            </BenefitItem>
            <hr />

            <ModalCheckUp openModal={openCheckUpModal} setOpenCheckUpModal={setOpenCheckUpModal} badgeEvent={badgeEvent} />

            {currentModal && <BenefitModalComponent
                open={open}
                setOpen={setOpen}
                close={() => { console.log("Close") }}
                key={'modal_' + index + '_' + Date.now()}
                category={membership.category}
                subType={membership.subtype}
                type={membership.type}
                modalData={currentModal}
            />

            }
        </>)
}

export default BenefitItemComponent;